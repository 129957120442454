const SESSION_URL = `${process.env.REACT_APP_BASE_DOMAIN}/_session`;
const TIMEOUT = 480000; // make the timeout 8 minutes

/**
 * You should **NOT** have to call this function directly. It will
 * be wired up correctly by just calling `login`.
 *
 * @param {fn} success callback when the user is still logged in
 * @param {fn} failure callback when the user was logged out, or an error occurred
 */
export function refresh(success, failure) {
  fetch(SESSION_URL, {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((body) => {
      if (body && body.userCtx && body.userCtx.name) {
        success(body.userCtx.name);

        setTimeout(() => {
          refresh(success, failure);
        }, TIMEOUT);
      } else {
        failure();
      }
    })
    .catch((e) => {
      // TODO: check if it failed due to network outage, in which case: retry!
      console.error(e);
      failure(e);
    });
}

/**
 * This function should be used to authenticate against a CouchDB backend.
 * Just provide  two handler functions and the credentials collected from
 * a form, everything else will be taken care of. This function also
 * automatically invokes the refresh function periodically, as long as the
 * application is running.
 *
 * @param {string} name couchdb username
 * @param {string} password couchdb password for the aforementioned user
 * @param {fn} success success callback function (set success state here)
 * @param {fn} failure callback in case of errors (set failure state here)
 */
export function login(name, password, success, failure) {
  fetch(SESSION_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      name,
      password,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .then((body) => {
      if (body.error) failure();
      else {
        //
        // Do not use setIntervall, instead use recursive setTimeout!
        // @see https://dev.to/akanksha_9560/why-not-to-use-setinterval--2na9
        //
        setTimeout(() => {
          refresh(success, failure);
        }, TIMEOUT);
        success();
      }
    })
    .catch((e) => {
      console.error(e);
      failure(e);
    });
}

/**
 * Terminate the users session (i. e. log them out).
 *
 * @param {fn} success callback when the user was logged out
 * @param {fn} failure callback when logging out failed (probably network error)
 */
export function logout(success, failure) {
  fetch(SESSION_URL, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((body) => {
      if (body.error) failure();
      else success();
    })
    .catch((e) => {
      console.error(e);
      failure(e);
    });
}
