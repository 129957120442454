import React from "react";
import { v4 as uuidv4 } from "uuid";
import { login, refresh } from "./auth";
import LoginForm from "./components/LoginForm";
import ToggleView from "./components/ToggleView";
import CategoryList from "./components/CategoryList";
import CategoryOverview from "./components/CategoryOverview";
import InputCategory from "./components/InputCategory";
import Navbar from "./styleguide/navbar";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import {
  setupRemote,
  addItemsToDB,
  editItemInDB,
  removeItemFromDB,
  listenForChanges,
  fetchItemsFromDB,
} from "./lib/database";
import InputItem from "./components/InputItem";

function emptyNewItem() {
  return {
    _id: uuidv4(),
    input: "",
    name: "",
    status: false,
    category: "",
  }
}

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      owner: "",
      pwd: "",
      loggedIn: false,
      wantLogin: false,
      allItems: [],
      allCategories: [],
      singleCategory: null,
      showAll: false,
      configCategoryMode: false,
      newCategory: "",
      temp: emptyNewItem(),
      filterOptions: { desc: false, type: "ALPHABET" },
    };
    this.dbSync = null;
  }

  componentDidMount() {
    fetchItemsFromDB(this.receiveItems.bind(this), () => {
      console.error("Failed in componentDidMount");
    });

    this.dbSync = listenForChanges(this.receiveItems.bind(this));

    refresh(
      (username) => {
        this.setState({
          loggedIn: true,
          owner: username,
          pwd: "",
        });

        setupRemote(username);
      },
      () => {
        console.error("invalid session - could not reload");
      }
    );
  }

  componentWillUnmount() {
    this.dbSync.cancel();
  }

  receiveItems(allItems, allCategories) {
    this.setState({
      allItems,
      allCategories,
    });
  }

  handleLogin = () => {
    login(
      this.state.owner,
      this.state.pwd,
      () => {
        setupRemote(this.state.owner);

        this.setState({
          wantLogin: false,
          loggedIn: true,
          pwd: "",
        });
      },
      () => {
        console.error("Could not login");
      }
    );
  };

  onOwnerChange(event) {
    this.setState({
      owner: event.target.value,
    });
  }

  onPwdChange(event) {
    this.setState({
      pwd: event.target.value,
    });
  }

  handleToggleView = () => {
    this.setState({ showAll: !this.state.showAll });
  };

  toggleLabel = () => {
    let label = "";
    if (this.state.showAll) {
      return (label = "Nur offene Artikel anzeigen");
    } else {
      return (label = "Alle Artikel anzeigen");
    }
  };

  sortDescending = () => {
    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        desc: true,
      },
    });
  };

  sortAscending = () => {
    this.setState({
      filterOptions: {
        ...this.state.filterOptions,
        desc: false,
      },
    });
  };

  handleconfigCategoryMode = () => {
    this.setState({ configCategoryMode: !this.state.configCategoryMode });
  };

  changeCategoryInput = (event) => {
    this.setState({ newCategory: event.target.value });
  };

  onChangeCategory = (event) => {
    console.log("tempCategory", this.state.tempCategory);
    event.preventDefault();
    this.setState({
      tempCategory: event.target.value,
    })
  };

  addNewCategory = (event) => {
    event.preventDefault();
    const newCategory = this.state.newCategory;
    const newPair = { [newCategory]: [] };
    let updatedCategories = Object.assign(this.state.allCategories, newPair);
    this.setState({
      allCategories: updatedCategories,
      configCategoryMode: false,
      newCategory: "",
    });
  };

  submitEditCategory = (event, index) => {

    let currentCategoriesCopy = this.state.allCategories.slice();
    let currentCatCopyIndex = currentCategoriesCopy.findIndex(
      (element) => element._id === index
    );
    currentCategoriesCopy.splice(currentCatCopyIndex, 1, this.state.tempCategory);
    //editItemInDB(this.state.temp);
    this.setState({
      // tempCategory: { [newCategory]: [] },
      allCategories: currentCategoriesCopy,
    });
  };

  handleSubmit = (event) => {
    if (this.state.temp.input != "") {
      if (this.state.editItemMode) {
        let currentItemsCopy = this.state.items.slice();
        let currentItemCopyIndex = currentItemsCopy.findIndex(
          (element) => element._id === this.state.temp._id
        );
        currentItemsCopy.splice(currentItemCopyIndex, 1, this.state.temp);
        editItemInDB(this.state.temp);
        this.setState({
          temp: emptyNewItem(),
          editItemMode: false,
          items: currentItemsCopy,
        });
      } else {
        event.preventDefault();
        addItemsToDB(this.state.temp);
        this.setState({
          temp: emptyNewItem(),
          allItems: this.state.allItems.concat(this.state.temp),
        });
      }
    }
  };

  resetInput = () => {
    this.setState({
      temp: emptyNewItem(),
    })
  }

  onChange = (event) => {
    const splittedInput = event.target.value.split("#");
    let nameInput = splittedInput[0];
    let categoryInputRaw = splittedInput[1];
    let categoryInput = "";
    let newItem = emptyNewItem();
    if (this.state.singleCategory === null) {
      categoryInputRaw
        ? (categoryInput = categoryInputRaw.trim())
        : (categoryInput = "unsortiert");
    } else {
      categoryInput = this.state.singleCategory;
    }
    if (this.state.editItemMode) {
      this.setState({
        temp: { ...this.state.temp, name: event.target.value },
      });
    } else {
      this.setState({
        temp: {
          ...newItem,
          input: event.target.value,
          name: nameInput,
          category: categoryInput,
        },
      });
    }
  };

  deleteCategory = ({ key }) => {
    let allCategoriesCopy = Object.assign({}, this.state.allCategories);

    for (var category in allCategoriesCopy) {
      allCategoriesCopy[category].map((currentItem) => {
        if (currentItem.category === key) {
          removeItemFromDB(currentItem);
        }
      });
    }

    delete allCategoriesCopy[key];

    this.setState({
      allCategories: allCategoriesCopy,
    });
  };

  showSingleCategory = (singleCat) => {
    this.setState({
      singleCategory: singleCat,
    });
  };

  showAllCategories = () => {
    this.setState({
      singleCategory: null,
    });
  };

  getListModeLabel = () => {
    let label = "";
    if (this.state.singleCategory === null) {
      return (label = "");
    } else {
      return (label = "Zurück zu allen Kategorien");
    }
  };

  render() {
    return (
      <div className="main-grid">
        <Router>
          <Switch>
            <Route path="/profile">
              <main>
                <span>
                  <LoginForm
                    onSubmit={() => this.handleLogin()}
                    owner={this.state.owner}
                    pwd={this.state.pwd}
                    onOwnerChange={this.onOwnerChange.bind(this)}
                    onPwdChange={this.onPwdChange.bind(this)}
                    onAbort={() => {
                      this.setState({ wantLogin: false });
                    }}
                    loggedIn={this.state.loggedIn}
                  />
                </span>
              </main>
            </Route>
            <Route path="/categories">
              <header>
                <InputCategory
                  addNewCategory={this.addNewCategory.bind(this)}
                  changeCategoryInput={this.changeCategoryInput.bind(this)}
                  newCategory={this.state.newCategory}
                  placeholder="Eingeben oder suchen"
                />
              </header>
              <main>
                <ul className="item-list">
                  <CategoryOverview
                    allCategories={this.state.allCategories}
                    deleteCategory={this.deleteCategory.bind(this)}
                    onChangeCategory={this.onChangeCategory.bind(this)}
                    tempCategory={this.state.tempCategory}
                  />
                </ul>
              </main>
            </Route>

            <Route path="/">
              <header>
                <InputItem
                  handleSubmit={this.handleSubmit.bind(this)}
                  onChange={this.onChange.bind(this)}
                  temp={this.state.temp}
                  placeholder="Eingeben oder suchen"
                />
              </header>
              <main>
                <button
                  className="button-link"
                  onClick={this.showAllCategories}
                >
                  {this.getListModeLabel()}
                </button>
                {Object.keys(this.state.allCategories).map((key, index) => {
                  let listBlock = (
                    <CategoryList
                      list={this.state.allCategories[key]}
                      category={key}
                      key={uuidv4()}
                      removeItemFromDB={removeItemFromDB}
                      showAll={this.state.showAll}
                      configCategoryMode={this.state.configCategoryMode}
                      handleSubmit={this.addNewCategory.bind(this)}
                      changeCategoryInput={this.changeCategoryInput.bind(this)}
                      resetInput={this.resetInput.bind(this)}
                      temp={this.state.temp}
                      filterOptions={this.state.filterOptions}
                      showSingleCategory={this.showSingleCategory.bind(this)}
                    />
                  );
                  if (this.state.singleCategory === null) {
                    return listBlock;
                  }
                  if (this.state.singleCategory === key) {
                    return listBlock;
                  } else {
                    return;
                  }
                })}
                <ToggleView
                  handleToggleView={() => this.handleToggleView()}
                  toggleLabel={() => this.toggleLabel()}
                />
              </main>
            </Route>
          </Switch>

          <footer className="footer primary">
            <Navbar
              toggleVisibility={() => this.handleToggleView.bind(this)}
              showAllItems={this.state.showAll}
              sortDescending={() => this.sortDescending.bind(this)}
              sortAscending={() => this.sortAscending.bind(this)}
            />
          </footer>
        </Router>
      </div>
    );
  }
}

class App extends React.Component {
  render() {
    return (
      <div className="shopping-list-app">
        <List />
      </div>
    );
  }
}

export default App;
