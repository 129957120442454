// sort list alphabetically
export function sortArrayAlphabetically(list, desc) {
  var mapped = list.map(function (item, i) {
    return {
      index: i,
      name: item.name ? item.name.toLowerCase() : typeof item === "string" ? item.toLowerCase() : "",
    };
  });

  mapped.sort(function (a, b) {
    if (!desc) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    } else {
      if (a.name < b.name) {
        return 1;
      }
      if (a.name > b.name) {
        return -1;
      }
      return 0;
    }
  });

  var sortedList = mapped.map(function (item) {
    return list[item.index];
  });
  return sortedList;
}
