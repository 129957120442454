import React from "react";

import { Plus } from "../styleguide/icons";

function InputItem({ handleSubmit, temp, onChange, placeholder }) {
  return (
    <form className="search-input" onSubmit={handleSubmit}>
      <input
        className="input is-rounded"
        type="text"
        value={temp.input || ""}
        onChange={onChange}
        placeholder={placeholder}
      />

      <button onClick={handleSubmit}>
        <Plus />
      </button>
    </form>
  );
}

export default InputItem;
