import React, { useState } from "react";
import { List, Tag, Filter, Sliders, Eye, EyeOff } from "./icons";
import { useLocation, Link } from "react-router-dom";

function Navbar({
  showAllItems,
  toggleVisibility,
  sortDescending,
  sortAscending,
}) {
  const currentUrl = useLocation().pathname;
  const isRoot = currentUrl === "/";
  const [showSortingDialog, setShowSortingDialog] = useState(false);

  return (
    <nav className="navbar">
      <ul>
        <li>
          <Link to="/" onClick={isRoot ? toggleVisibility() : null}>
            {isRoot ? showAllItems ? <Eye /> : <EyeOff /> : <List />}
            <span>
              {isRoot ? (showAllItems ? "Offene" : "Alle") : "Liste zeigen"}
            </span>
          </Link>
        </li>
        <li>
          <Link to="/categories">
            <Tag />
            <span>Kategorien</span>
          </Link>
        </li>
        <li className="navbar-button">
          <button onClick={() => setShowSortingDialog(!showSortingDialog)}>
            <Filter />
            <span>Sortierung</span>
          </button>
        </li>
        <li>
          <Link to="/profile">
            <Sliders />
            <span>Konto</span>
          </Link>
        </li>
      </ul>
      <div>
        <SortingDialog
          showSortingDialog={showSortingDialog}
          sortDescending={sortDescending}
          sortAscending={sortAscending}
        />
      </div>
    </nav>
  );
}

function SortingDialog({ showSortingDialog, sortDescending, sortAscending }) {
  if (showSortingDialog) {
    return (
      <div className="sorting-dialog">
        <ul>
          <li>
            <button onClick={sortAscending()}>Alphabetisch aufsteigend</button>
          </li>
          <li>
            <button onClick={sortDescending()}>Alphabetisch absteigend</button>
          </li>
        </ul>
      </div>
    );
  }
  return null;
}

export default Navbar;
