import React from "react";
import "../App.scss";

function ToggleView({ handleToggleView, toggleLabel }) {
  return (
    <div className="button-link">
      <button className="button-link" onClick={handleToggleView}>
        {toggleLabel()}
      </button>
    </div>
  );
}

export default ToggleView;
