import PouchDB from "pouchdb";
import { convertToHex } from "./converter";

let db = new PouchDB("shoppinglist");

export function setupRemote(username) {
  const remoteDbName = convertToHex(username);

  var remoteDB = new PouchDB(
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_BASE_DOMAIN}/shopping_list`
      : `${process.env.REACT_APP_BASE_DOMAIN}/userdb-${remoteDbName}`
  );

  db.sync(remoteDB, {
    live: true,
    timeout: false,
    retry: true,
  }).on("error", function (err) {
    console.error("Sync failed due to: ", err);
  });
}

export function addItemsToDB(tempItem) {
  var item = {
    _id: tempItem._id,
    name: tempItem.name,
    status: false,
    category: tempItem.category,
  };
  db.put(item, function callback(err, result) {
    if (err) {
      console.error("Could not put item into DB: ", err);
    }
  });
}

export function editItemInDB(tempItem) {
  db.put(tempItem, function callback(err, result) {
    if (err) {
      console.error("Updating item failed in editItemInDB", err);
    }
  });
}

export function removeItemFromDB(item) {
  db.remove(item, function callback(err, result) {
    if (err) {
      console.error("Removing item failed in removeItemInDB", err);
    }
  });
}

export function fetchItemsFromDB(success, error) {
  db.allDocs({
    include_docs: true,
    descending: true,
  })
    .then((result) => {
      const rows = result.rows;

      let categories = {
        unsortiert: [],
      };

      rows.forEach((row) => {
        const category = row.doc.category;
        if (category) {
          if (!(category in categories)) {
            categories[category] = [];
          }
          categories[category].push(row.doc);
        } else {
          categories.unsortiert.push(row.doc);
        }
      });

      const allItems = rows.map((row) => row.doc);

      success(allItems, categories);
    })
    .catch((err) => {
      console.error("Could not fetch items from DB", err);
      error();
    });
}

export function listenForChanges(successHandler) {
  return db
    .changes({
      since: "now",
      live: true,
      include_docs: true,
    })
    .on("change", () => {
      fetchItemsFromDB(successHandler, () => {
        console.error("Failed in database/listenForChanges");
      });
    });
}

export default db;
