import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Trash, Edit, Check } from "../styleguide/icons";
import { sortArrayAlphabetically } from "../lib/helperFunctions";
import "../App.scss";

function CategoryOverview({ allCategories, deleteCategory, onChangeCategory, tempCategory, submitEditCategory }) {
  const [tempKey, setTempKey] = useState("");
  const [tempEditIndex, setTempEditIndex] = useState("");

  function confirmDelete() {
    deleteCategory(tempKey);
    setTempKey("");
    console.log("unset!");
  }

  if (tempKey !== "") {
    return (
      <div className="alert">
        <span>Es werden auch alle Einträge in dieser Kategorie gelöscht!</span>
        <div className="button-group">
          <button onClick={() => confirmDelete()}>OK</button>
          <button onClick={() => setTempKey("")}>Nein</button>
        </div>
      </div>
    );
  } else {
    console.log("allCat", allCategories);
    return sortArrayAlphabetically(Object.keys(allCategories)).map(
      (key, index) => {
        return (
          <li key={uuidv4()}>
            { (tempEditIndex != "")
              ? <span>
                <input
                  type="text"
                  placeholder={key}
                  onChange={onChangeCategory}
                />
                <span>
                  <button onClick={() => {/*setTempEditIndex(""); submitEditCategory(index)*/}}>
                    <Check />
                  </button>
                </span>
              </span>
              : <span className="title">{key}
                {/* <button onClick={() => setTempEditIndex({ index })}>
                  <Edit />
                </button> */}
              </span>
            }

            <span>
              <button className="trash" onClick={() => setTempKey({ key })}>
                <Trash />
              </button>
            </span>
          </li>
        );
      }
    );
  }
}

export default CategoryOverview;
