import React from "react";
import Item from "./Item";
import InputItem from "./InputItem";
import ItemRow from "./ItemRow";
import { editItemInDB } from "../lib/database";
import { sortArrayAlphabetically } from "../lib/helperFunctions";

class CategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: props.category,
      tempCategory: "",
      temp: props.temp,
      showAll: props.showAll,
      editItemMode: false,
      editCategoryMode: false,
      items: props.list,
    };
    this.removeItemFromDB = props.removeItemFromDB;
    this.configCategoryMode = props.configCategoryMode;
    this.handleCategoryEditButton = this.handleCategoryEditButton.bind(this);
    this.showSingleCat = this.showSingleCat.bind(this);
    this.changeCategoryInput = props.changeCategoryInput;
    this.showSingleCategory = props.showSingleCategory;
    this.resetInput = props.resetInput;
  }

  showItems = (list, filterOptions) => {
    var sortedList = sortArrayAlphabetically(list, filterOptions.desc);

    // filter items when a new input occures
    let filteredList =
      this.state.temp.name !== ""
        ? sortedList.filter((item) =>
            item.name.toLowerCase().includes(this.state.temp.name.toLowerCase())
          )
        : sortedList;

    // render whole list if showAll is true or while searching, or show only unchecked items
    return filteredList.map((item) => {
      const comp = (
        <Item
          key={item._id}
          item={item}
          handleCheckboxClick={() => this.handleCheckboxClick(item)}
          onClickDelete={() => this.handleDeleteButton(item)}
          onClickEdit={() => this.handleEditButton(item)}
        />
      );
      return this.state.showAll
        ? comp
        : !this.state.showAll && this.state.temp.name !== ""
        ? comp
        : !this.state.showAll && !item.status
        ? comp
        : null;
    });
  };

  handleCheckboxClick = (currentItem) => {
    // copy array to mutate
    let currentItemsCopy = this.state.items.slice();
    let currentItemCopy = currentItemsCopy.find(
      (element) => element._id === currentItem._id
    );
    currentItemCopy.status = !currentItem.status;
    this.setState({
      items: currentItemsCopy,
      temp: { _id: "", name: "", status: false },
    });
    editItemInDB(currentItemCopy);
    this.resetInput();
  };

  handleDeleteButton = (currentItem) => {
    let currentItemsCopy = this.state.items.slice();
    let currentItemCopyIndex = currentItemsCopy.findIndex(
      (element) => element._id === currentItem._id
    );
    currentItemsCopy.splice(currentItemCopyIndex, 1);
    this.setState({
      items: currentItemsCopy,
    });
    this.removeItemFromDB(currentItem);
  };

  handleEditButton = (currentItem) => {
    this.setState({
      editItemMode: true,
      temp: currentItem,
    });
  };

  handleCategoryEditButton = () => {
    this.setState({
      editCategoryMode: true,
      tempCategory: this.state.category,
    });
  };

  onChangeCategory = (event) => {
    event.preventDefault();
    if (this.state.editCategoryMode) {
      this.setState({
        tempCategory: event.target.value,
      });
    }
  };

  handleSubmitCategory = (event) => {
    let currentItemsCopy = this.state.items.slice();
    currentItemsCopy.forEach((item, index) => {
      let temp = {
        ...item,
        category: this.state.tempCategory,
      };

      this.currentItemsCopy.splice(index, 1, temp);
      editItemInDB(temp);
    });

    this.setState({
      category: this.state.tempCategory,
      tempCategory: "",
    });
  };

  showSingleCat = () => {
    this.showSingleCategory(this.state.category);
  };

  render() {
    return (
      <>
        <div className="container column is-10 is-offset-1">
          <div className="column is-mobile is-10 is-offset-1">
            <ItemRow
              category={this.state.category}
              configCategoryMode={this.configCategoryMode}
              handleCategoryEditButton={this.handleCategoryEditButton}
              tempCategory={this.state.tempCategory}
              changeCategoryInput={this.changeCategoryInput}
              editCategoryMode={this.state.editCategoryMode}
              showSingleCat={this.showSingleCat}
            />
          </div>
        </div>
        <div>
          <div>
            <div className="box content">
              <ul className="item-list">
                {this.showItems(this.state.items, this.props.filterOptions)}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CategoryList;
