import React from "react";
// import "../App.scss";
import { Trash, Edit, Check } from "../styleguide/icons";

function Item({ item, id, handleCheckboxClick, onClickEdit, onClickDelete }) {
  return (
    <li>
      <label className={item.status ? "checkbox active" : "checkbox"}>
        {item.status ? <Check /> : null}
        <input
          type="checkbox"
          checked={item.status}
          id={id}
          onChange={handleCheckboxClick}
        />
      </label>
      <span className="title">{item.name}</span>
      <span>
        {/* <button onClick={onClickEdit}>
          <Edit />
        </button> */}
        <button onClick={onClickDelete} className="trash">
          <Trash />
        </button>
      </span>
    </li>
  );
}

export default Item;
