import React from "react";
import "../App.scss";
import InputCategory from "./InputCategory";
import { Tag } from "../styleguide/icons";

function toggleCategoryConfigButtons({
  configCategoryMode,
  handleCategoryEditButton,
}) {
  if (configCategoryMode) {
    return (
      <>
        <button
          type="button"
          className="button is-small is-info is-outlined"
          // refer to handleCategoryEditButton, do not call with () to avoid re-rendering loops
          onClick={handleCategoryEditButton}
        >
          <span className="icon is-small">
            <i className="fas fa-pen"></i>
          </span>
        </button>
        <button
          className="button is-small is-info is-outlined"
          type="button"
          //onClick={props.onClickDelete}
        >
          <span className="icon is-small">
            <i className="fas fa-trash"></i>
          </span>
        </button>
      </>
    );
  }
}

function ItemRow({
  editCategoryMode,
  addNewCategory,
  changeCategoryInput,
  tempCategory,
  showSingleCat,
  category,
  configCategoryMode,
  handleCategoryEditButton,
}) {
  if (editCategoryMode) {
    return (
      <InputCategory
        handleSubmit={addNewCategory}
        onChange={changeCategoryInput}
        value={tempCategory}
      />
    );
  }

  return (
    <div>
      <h2 className="category-title">
        <Tag />
        <span>
          <button className="button-link" onClick={showSingleCat}>
            {category || ""}
          </button>
        </span>
      </h2>
      <div>
        {toggleCategoryConfigButtons(
          configCategoryMode,
          handleCategoryEditButton
        )}
      </div>
    </div>
  );
}

export default ItemRow;
