import React from "react";

import { Plus } from "../styleguide/icons";

function InputCategory({
  changeCategoryInput,
  newCategory,
  addNewCategory,
  placeholder,
}) {
  return (
    <form className="search-input" onSubmit={addNewCategory}>
      <input
        className="input is-rounded"
        type="text"
        value={newCategory || ""}
        onChange={changeCategoryInput}
        placeholder={placeholder}
      />
      <button onClick={addNewCategory}>
        <Plus />
      </button>
    </form>
  );
}

export default InputCategory;
