import React from "react";

function LoginForm({
  owner,
  loggedIn,
  onOwnerChange,
  pwd,
  onPwdChange,
  onSubmit,
}) {
  return owner && loggedIn ? (
    <>
      <h2>Dein Konto</h2>
      <p>
        Du bist angemeldet als: <strong>{owner}</strong>
      </p>
    </>
  ) : (
    <>
      <h2>Bitte melde Dich an</h2>
      <section className="login-form">
        <label htmlFor="username">Name</label>

        <input
          type="text"
          placeholder="Name"
          name="uname"
          value={owner || ""}
          onChange={onOwnerChange}
          required
        />

        <label htmlFor="psw">Password</label>

        <input
          type="password"
          placeholder="Password"
          name="psw"
          value={pwd || ""}
          onChange={onPwdChange}
          required
        />
        <div className="button-container">
          <button type="button" onClick={onSubmit}>
            Login
          </button>
        </div>
      </section>
    </>
  );
}

export default LoginForm;
